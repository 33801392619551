body {
  text-align: center;
      background-color: #f2f2f2;
  margin: 0;
  font-family: overpass-variable, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  margin:0px 0px;


}
h1, h2, h3, h4, h5, h6 {
  font-variation-settings: "wght" 900;

}
p {
  font-size:17px;
}
h3 {
  font-size: 25px;
}
h4 {
  font-size: 23px;
}
h5 {
  font-size: 21px;
}
h6 {
  font-size: 16px;
  font-variation-settings: "wght" 300;
}
.center_site {
  margin:0 auto;
  max-width:1000px;
/*  overflow: hidden;*/
}



.page {

  width:100%;
  /*background: rgb(226,226,226);
  background: linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(233,233,233,1) 100%);*/
  background-image: url('img/large_stucco.jpg');
  background-repeat: repeat-y;
  background-size: cover;
  background-position: top left;
  min-height: 200px;
  display: inline-block;
}



.center_box_text {
  text-align: left;
  padding:20px;
}

.center_contact_text {
  text-align:center;
  color:#000;
}
.center_box {
  width:680px;
  min-height: 100px;
  text-align: center;
  margin: 0 auto;
  margin-top:100px;
  background-color: white;
  margin-bottom:100px;
}

.center_box_heading {
  height:40px;
  background-color:#c1272c;
  color:#FFF;
  text-align: center;
  font-size:26px;
  font-variation-settings: "wght" 900;
}
@media (max-width: 680px) {
  .center_box {
    width:100%;
  }
  .center_box {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/* HEADER */
.header {
  position: relative;
  z-index: 51;
  margin-top: 0px;
  width:calc(100%);
  padding:30px 24px;

  mask-position: bottom center;
  mask-size: 902px;
  background-color: white;
  text-align: left;

  background-image: url('img/large_stucco.jpg');
  background-repeat: repeat-y;
  background-size: cover;
  height:calc(200px - 68px);
}


.logo {
  text-align: center;
  width:318px;
  display: inline-block;
  cursor: pointer;
  width:100%;
}
.header_menu {
  vertical-align: top;
  display: inline-block;
  width:calc(100%);
  text-align: center;
  height:44px;
  width:100%;
  background-color:white;
  border-top: solid 4px #dfdfdf;
  border-bottom:solid 4px #c1272c;
}
.text_header_items {
  margin-top: -6px;
}
.header_menu_item {
  font-size:20px;
  margin-top:14px;
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
  cursor: pointer;
}
.header_menu_img {
  margin-top: -8px;
}
.header_menu_txt {
  display: inline-block;
  vertical-align: middle;
  margin-top: -8px;
  margin-left:8px;

}


.hamburger_icon {

  vertical-align: top;
  cursor: pointer;
  display: none;
  background-image: url('img/hamburger.png');
  background-position: center center;
  background-repeat: no-repeat;
  width: 57px;
  height: calc(132px - 40px);
  margin: -20px 0px 0px 0px;
  background-size: contain;
  position: absolute;
  right:20px;
}



.mobile_menu {
  display: none;
  position: absolute;
  top: 136px;
  left: 0px;
  width: 100%;
  background-color: white;
  line-height: 80px;
  z-index: 300;
}
.mobile_menu_li {
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 24px;
  border-bottom: solid 4px #dfdfdf;

}
.header_mobile_menu_img {
  display: inline-block;
  vertical-align: middle;
}
.mobile_menu_li_txt {
  margin-left: 10px;
  display: inline-block;

}
.mobile_menu_li a, mobile_menu_li a:hover {
  color:#000;
  text-decoration: none;
}

#mobile_contact_bb {
  width: 100%;
    display: none;
  }
  #fullweb_contact_bb {
    display: inline-block;
  }

@media (max-width: 760px) {
  .header_menu_item {
    display: none;
  }
  .hamburger_icon {
    display: inline-block;
  }
  #fullweb_contact_bb {
    display: none;
  }
  #mobile_contact_bb {
    display: inline-block;
  }
  .header_menu {
    height:0px;
    border-top: solid 4px #dfdfdf;
    border-bottom:solid 0px #c1272c;
  }
}



.service_block {
  position: relative;
  z-index: 48;
  margin-top: -10px;

  padding:var(--standard-y-padding) var(--standard-x-padding);
  
  width: calc(100%);

  min-height:calc(50px);
  text-align: left;
  background-color: var(--brand-blue);
  color:#FFF;

  -webkit-mask-image: url("img/dot_mask.png");
  mask-image: url("img/dot_mask.png");
  mask-position: bottom center;
  mask-size: 902px;
  background-size:cover;
  background-position: center;

/*  border-bottom: solid 8px var(--brand-red);*/

/*  image-rendering: auto;*/


}

.blue_block {
  position: relative;
  z-index: 48;
  margin-top: -10px;

  padding:var(--standard-y-padding) var(--standard-x-padding);
  
  width: calc(100%);

  min-height:calc(50px);
  text-align: left;
  background-color: var(--brand-blue);
  color:#FFF;

  -webkit-mask-image: url("img/dot_mask.png");
  mask-image: url("img/dot_mask.png");
  mask-position: bottom center;
  mask-size: 902px;
  mask-repeat: repeat-x;
/*  image-rendering: auto;*/


}

.red_block {
  position: relative;
  z-index: 48;
  margin-top: -10px;

  padding:var(--standard-y-padding) var(--standard-x-padding);
  
  width: calc(100%);

  min-height:calc(50px);
  text-align: left;
  background-color: var(--brand-red);
  color:#FFF;

  -webkit-mask-image: url("img/dot_mask.png");
  mask-image: url("img/dot_mask.png");
  mask-position: bottom center;
  mask-size: 902px;
  mask-repeat: repeat-x;
/*  image-rendering: auto;*/


}
.send_message {
  margin-top:-20px;
  z-index: 46;
}

.white_block {
  position: relative;
  z-index: 48;
  margin-top: -10px;

  padding:var(--standard-y-padding) var(--standard-x-padding);
  
  width: calc(100%);

  min-height:calc(50px);
  text-align: left;
  background-color: #cccccc;
  color:#000;

  -webkit-mask-image: url("img/dot_mask.png");
  mask-image: url("img/dot_mask.png");
  mask-position: bottom center;
  mask-size: 902px;
  mask-repeat: repeat-x;
/*  image-rendering: auto;*/


}

.no_padding {
  padding: 0px;
}


.button_text_with_img {
  margin-top:2px;
  display: inline-block;
  vertical-align: top;
}
.button_text_with_img_sm {
  margin-top:0px;
  display: inline-block;
  vertical-align: top;
}
.button_text_with_img_md {
  margin-top:5px;
  display: inline-block;
  vertical-align: top;
}


@media (max-width: 640px) {

  .freight_since {
    width:calc(90%);
    height:calc(80%);
  }

  .service_block {
    height:240px;
  }

}



.fleet_box_darker {
  background-color: #bd131f;
}

.fleet_box_blue_lighter {
  background-color: #172c62;
}
.fleet_box_blue {
  background-color: #11214a;
}
.fleet_box_blue_darker {
  background-color: #0f1e44;
}
.fleet_text {
  float:left;
  width:calc(100% - 500px);

}

.center_text {
  text-align: center;
}

.truck_box {
  
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  width:calc(500px);
  height:76px;
  float:right;
  margin-top: 50px;
}

@media (max-width: 820px) {

  .truck_box {
    margin-top:40px;
    width:100%;
    float:left;
    text-align: left;
    background-position: center right;

  }
  .fleet_text {
    width:calc(100%);
  }
  .fleet_box {
    height:280px;
  }
}


@media (max-width: 800px) {

  .truck_box {
    margin-top:40px;
    width:100%;
    float:left;
    text-align: left;
    background-position: center right;

  }
  .fleet_text {
    width:calc(100%);
  }
  .fleet_box {
    height:320px;
  }
}

.footer_box {
  position: relative;
  z-index: 5;

  
  background-image: url('img/footer_texture.jpg');
  background-size:cover;
  background-repeat: repeat-y;
  width:100%;
  color:#FFF;
  min-height:200px;

}
.thick {
  font-variation-settings: "wght" 900;
}
.footer_logo {

  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px 70px;
  width:100px;
  height:180px;
  float:right;
  text-align: right;
  vertical-align: middle;
}
.footer_address {
  font-size:16px;
  float:right;
  width:240px;
  height:180px;
  text-align: right;
  padding:var(--standard-y-padding) var(--standard-x-padding);
  line-height: 1.1em;
}

.list {
  width: 140px;
  text-align: left;
  padding-top: var(--standard-y-padding);
  padding-bottom: var(--standard-y-padding);
  float: left;
  font-size: 16px;
  line-height: 1.1em;
}
.list ul {
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: var(--standard-x-padding);
}
.list li {
  padding: 4px 0px;
}
.list_head {
  font-family:"filicudi-solid", sans-serif;
  font-weight: bold;
  font-size: 24px;
  cursor: none;
  margin-top: 20px;
}
.red_link, .red_link a {
  color: #c1272c;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
.red_link:hover, .red_link a:hover {
  color: #c1272c;
}


.classic_img {
  background-image: url("img/classic.jpg");
  background-size: cover;
  width:100%;
  height:400px;

}



.white_link, .white_link a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
.white_link:hover, .white_link a:hover {
  color: var(--brand-red);
}

.black_link, .black_link a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.black_link:hover, .black_link a:hover {
  color: var(--brand-red);
  text-decoration: none;
}

.footer_phone {
  font-size: 18px;
  font-variation-settings: "wght" 900;
  line-height: 1.3em;
}
.footer_email {
  font-size: 0.9em;
}


.copyright {
  margin-top:10px;
  font-size:12px;
  color:#000;
  top:1420px;
  width:100%;
  height:40px;
}

.full_footer {
  display: inline-block;
  width:100%;
  min-height:200px;
}
.mobile_footer {
  font-size: 1.2em;
  display: none;
  color: #FFF;
  background-image: url('img/footer_texture.jpg');
  width:  100%;
  margin: 0px auto;
  padding: 40px 40px 10px 40px;
  text-align: center;
}
@media (max-width: 760px) {
  .mobile_footer {
    display: inline-block;
  }
  .full_footer {
    display: none;
  }
}
.mobile_footer_abox_left {
  float: left;
}
.mobile_footer_abox_right {
  float: right;
  margin-left: 16px;
}

.mobile_list ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.mobile_list li {
  padding: 16px 0px;
  font-size: 20px;
  border-top: solid 1px #FFF;
/*  border-bottom: solid 2px #FFF;*/
}
.mobile_list {
  font-family:"filicudi-solid", sans-serif;
  width: calc(100%);
}


.social_img {
  margin: 4px 0px 4px 8px;
  cursor: pointer;
}

.clear {
  clear:both;

}
.hidden {
  display: none;
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--brand-red);
    --bs-btn-border-color: var(--brand-red);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--brand-red-lighter);
    --bs-btn-hover-border-color: var(--brand-red-lighter);
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--brand-red);
    --bs-btn-active-border-color: var(--brand-red);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--brand-blue);
    --bs-btn-border-color: var(--brand-blue);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--brand-blue-lighter);
    --bs-btn-hover-border-color: var(--brand-blue);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--brand-red);
    --bs-btn-active-border-color: var(--brand-red);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.fifty_percent {
  width:50%;
  display: inline-block;
  vertical-align: top;
}
#map {
  float:right;
  margin: 30px 30px 30px 0px;
  display: inline-block;
    height: 144px; /* The height is 400 pixels */
    width: 300px; /* The width is the width of the web page */
}
#map_large {
  width:calc(100%);
  height:400px;
}

/* FORM STUFF */
.form-control {
  background-color: #FFF;
}
.form-control:focus {
  background-color: #FFF;
}
.form-control.textarea.readonly {
  color: grey;
}

.an_error {
  color: var(--brand-red);
}
.small_error_text {
  line-height: 1.2em;
  font-size: 15px;
  font-style: italic;
}

.form_error_message {
  color: var(--brand-blue);
}
.red_block .an_error {
  color:#FFF;
}
.red_block .form_error_message {
  color:#FFF;
}
.react-datepicker-wrapper input {
    
    color: #000;
    
    background-color: #FFF;
    width: 100%;
    padding: 0.375rem 0.75rem;
    
    border: 1px solid #ced4da;
    border-radius: 0.375rem;

}
.left_service {
  float:left;
}
.right_service {
  float:right;
}

.service_icon {
  display: inline-block;
  width:100px;
  height:100px;
  
    mix-blend-mode: normal;
    text-align: center;
    vertical-align: middle;
    border-radius: 15px;
    padding-top:5px;
    cursor: pointer;
}
.service_desc {
  display: inline-block;
  width: calc(100% - 120px);
  height: 100px;
  padding-left:20px;
  vertical-align: middle;
}
.make_white {
   filter: invert(100%);
}




@media (max-width: 760px) {
  .left_service {
    text-align: center;
     width:100%;
  }
  .right_service {
    float:left;
    margin-top:20px;
    width:100%;
    text-align:center;
  }
  .two_button_ai_landscape {
    height:200px;
  }
  #map, #map2 {
    border-radius: 0px;
     
}

}

@media (max-width: 760px) {
  .two_button_blue_block {
    height:200px;
  }
}

@media (max-width: 550px) {
  .two_button_blue_block {
    height:260px;
  }
}

